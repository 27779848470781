
	/* eslint-disable vue/match-component-file-name */
	import { useEntityStore, useGlobalStore } from '~stores/index.js';
	import { mapStores } from 'pinia';
	import { isContentOnlyPage } from '~/scripts/helpers';

	let areStoresInitialized = false;

	const entityMappings = {
		'page-index': () => import(`~pages/page-index/page-index.vue`),
		'page-404': () => import(`~pages/page-404/page-404.vue`),
		'page-catalog': () => import(`~pages/page-catalog/page-catalog.vue`),
		'page-catalog-single': () => import(`~pages/page-catalog-single/page-catalog-single.vue`),
		'page-text': () => import(`~pages/page-text/page-text.vue`),
		'page-favorites': () => import(`~pages/page-favorites/page-favorites.vue`),
		'page-search': () => import(`~pages/page-search/page-search.vue`),
		'page-checkout': () => import(`~pages/page-checkout/page-checkout.vue`),
		'page-cart': () => import(`~pages/_order/page-cart/page-cart.vue`),
		'page-order': () => import(`~pages/_order/page-order/page-order.vue`),
		'page-reviews': () => import(`~pages/_reviews/page-reviews/page-reviews.vue`),
		'page-reviews-creation-form': () => import(`~pages/_reviews/page-reviews-creation-form/page-reviews-creation-form.vue`),
		'page-reviews-improvement-form': () => import(`~pages/_reviews/page-reviews-improvement-form/page-reviews-improvement-form.vue`),
		'page-personal-auth': () => import(`~pages/_personal/page-personal-auth/page-personal-auth.vue`),
		'page-personal-orders': () => import(`~pages/_personal/page-personal-orders/page-personal-orders.vue`),
		'page-personal-orders-single': () => import(`~pages/_personal/page-personal-orders-single/page-personal-orders-single.vue`),
		'page-personal-settings': () => import(`~pages/_personal/page-personal-settings/page-personal-settings.vue`),
		'page-personal-bonuses': () => import(`~pages/_personal/page-personal-bonuses/page-personal-bonuses.vue`),
		'page-personal-notifications': () => import(`~pages/_personal/page-personal-notifications/page-personal-notifications.vue`),
		'page-success': () => import(`~pages/page-success/page-success.vue`),
		'page-catalog-seo': () => import(`~pages/page-catalog-seo/page-catalog-seo.vue`),
		// {{mapping}}
	};

	const blankLayoutEntities = [
		'page-personal-auth',
		'page-personal-settings',
	];

	const appendPaginationInfo = (type, value, page) => {
		if (page === 1) return value;
		value = value.trim().replace(/\.$/, '').replace(/(?: - )?(?:. )?[Сс]траница №?\d\.?/, '');

		(type === 'page') && (value += ` - страница №${page}`);
		(type === 'description') && (value += `. Страница №${page}`);

		return value;
	};

	export default {
		name: 'page-wrapper',
		components: {},

		layout(ctx) {
			if (isContentOnlyPage(ctx.query)) return 'blank';
			const currentEntity = ctx.$pinia.state.value.entity.current.entity;
			return blankLayoutEntities.includes(currentEntity)
				? 'blank'
				: 'default';
		},

		// Just to show them in DevTools
		setup() {
			if (!process.server) {
				useEntityStore();
				useGlobalStore();
			}
		},

		async asyncData(context) {
			if (context.route.path === '/auth/' && context.route.query.logout === 'yes') {
				await context.store.dispatch('user/logout', { context });
				context.redirect(307, '/auth/');
				return {};
			}

			const { $pinia } = context;
			const entityStore = useEntityStore($pinia);
			const globalStore = useGlobalStore($pinia);

			const [currentData] = await Promise.all([
				entityStore.init(),
				// Перезапрос актуальной даты и времени при переходе между страницами
				// При первой загрузке происходит в `store/index.js`
				process.client && globalStore.init(),
			]);

			entityStore.setInitialized();

			const {
				entity, head, breadcrumbs, pageInfo, __redirectStatus, __redirectUrl, microData, ...entityParams
			} = currentData ?? entityStore.current ?? {};

			if (!entity) {
				context.redirect(__redirectStatus, __redirectUrl);
				return {};
			}

			const vuexStore = context.store.state;
			if (entity === 'page-checkout' && vuexStore.cart.items.length === 0) {
				context.redirect(302, vuexStore.siteLinks.cart);
				return {};
			}

			context.store.commit('intersection/RESET_INTERSECTIONS');

			const entityModule = await entityMappings[entity]();
			const asyncData = await entityModule.asyncData(context, entityParams);
			entityModule.modifyHead?.(context, head);

			entityStore.setCurrent(currentData);

			return {
				entity, head, breadcrumbs, pageInfo, asyncData, microData,
			};
		},

		head() {
			// На страницах каталога в `title` и `meta.description` должна содержаться информация о номере страницы.
			// Почему-то важно, чтобы при переходах между страницами эта информация менялась динамически.
			// При переходах между страницами каталога `/entity/` не перезапрашивается, поэтому делается на фронте.
			if (this.entity === 'page-catalog') {
				const page = parseInt(this.$route.query.page, 10) || 1;

				this.head.title = appendPaginationInfo('page', this.head.title, page);

				const description = this.head.meta?.find((i => i.name === 'description'));
				description && (description.content = appendPaginationInfo('description', description.content, page));
			}

			return this.head;
		},

		computed: {
			...mapStores(useGlobalStore),

			pageComponent() {
				return entityMappings[this.entity];
			},
		},

		jsonld() {
			return this.microData || null;
		},

		mounted() {
			if (!areStoresInitialized) {
				this.$store.dispatch('cart/init');
				this.$store.dispatch('favorites/init');
				this.$store.dispatch('user/init');
				areStoresInitialized = true;
			}

			this.$broadcastChannel.registerListeners('globals', () => {
				const updateFavorites = () => this.$store.dispatch('favorites/init');
				const updateCart = () => this.$store.dispatch(`cart/init`);
				const updateDate = () => this.globalStore.init();

				this.$broadcastChannel.onMessage('favorites', updateFavorites);
				this.$broadcastChannel.onMessage('cart', updateCart);
				this.$broadcastChannel.onMessage('date', updateDate);
				this.$broadcastChannel.onMessage('auth', async () => {
					await Promise.all([
						updateDate(),
						updateFavorites(),
						updateCart(),
						this.$store.dispatch('user/init'),
					]);
					this.$nuxt.refresh();
				});
			});

			// Mobile console
			// const script = document.createElement('script');
			// script.src = 'https://unpkg.com/vconsole@latest/dist/vconsole.min.js';
			// script.onload = () => new window.VConsole();
			// document.body.append(script);
		},
	};
